/************************************************* KeyFrames **************************************************/
@-webkit-keyframes invertColors {
  0% {
    background-color: #fff;
    border-color: #fff;
    color: #333;
  }
  100% {
    background-color: #333;
    border-color: #333;
    color: #fff;
  }
}
@keyframes invertColors {
  0% {
    background-color: #fff;
    border-color: #fff;
    color: #333;
  }
  100% {
    background-color: #333;
    border-color: #333;
    color: #fff;
  }
}

@-webkit-keyframes reverseInvertColors {
  0% {
    background-color: #333;
    border-color: #333;
    color: #fff;
  }
  100% {
    background-color: #fff;
    border-color: #fff;
    color: #333;
  }
}

@keyframes reverseInvertColors {
  0% {
    background-color: #333;
    border-color: #333;
    color: #fff;
  }
  100% {
    background-color: #fff;
    border-color: #fff;
    color: #333;
  }
}

@-webkit-keyframes rainbowBorder {
  0% {
    border-image: #fff, 7;
  }
  14% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(white), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, white, white, white, white, white, white) 7;
  }
  28% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, white, white, white, white, white) 7;
  }
  42% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, white, white, white, white) 7;
  }
  56% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, white, white, white) 7;
  }
  70% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, white, white) 7;
  }
  85% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, white) 7;
  }
  100% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(violet)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet) 7;
  }
}

@keyframes rainbowBorder {
  0% {
    -o-border-image: #fff, 7;
       border-image: #fff, 7;
  }
  14% {
    -o-border-image: linear-gradient(to right, red, white, white, white, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(white), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, white, white, white, white, white, white) 7;
  }
  28% {
    -o-border-image: linear-gradient(to right, red, orange, white, white, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, white, white, white, white, white) 7;
  }
  42% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, white, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, white, white, white, white) 7;
  }
  56% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, white, white, white) 7;
  }
  70% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, blue, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, blue, white, white) 7;
  }
  85% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, white) 7;
  }
  100% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(violet)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet) 7;
  }
}

@-webkit-keyframes reverseRainbowBorder {
  0% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(violet)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet) 7;
  }
  14% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, white) 7;
  }
  28% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, white, white) 7;
  }
  42% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, white, white, white) 7;
  }
  56% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, white, white, white, white) 7;
  }
  70% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, white, white, white, white, white) 7;
  }
  85% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(white), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, white, white, white, white, white, white) 7;
  }
  100% {
    border-image: #fff, 7;
  }
}

@keyframes reverseRainbowBorder {
  0% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(violet)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet) 7;
  }
  14% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, white) 7;
  }
  28% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, blue, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, blue, white, white) 7;
  }
  42% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, white, white, white) 7;
  }
  56% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, white, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, white, white, white, white) 7;
  }
  70% {
    -o-border-image: linear-gradient(to right, red, orange, white, white, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, white, white, white, white, white) 7;
  }
  85% {
    -o-border-image: linear-gradient(to right, red, white, white, white, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(white), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, white, white, white, white, white, white) 7;
  }
  100% {
    -o-border-image: #fff, 7;
       border-image: #fff, 7;
  }
}

@-webkit-keyframes slideBackground {
  0% {
    background-position: 100% 0;
    color: #333;
  }
  100% {
    background-position: 0 0;
    color: #fff;
  }
}

@keyframes slideBackground {
  0% {
    background-position: 100% 0;
    color: #333;
  }
  100% {
    background-position: 0 0;
    color: #fff;
  }
}

@-webkit-keyframes reverseSlideBackground {
  0% {
    color: #fff;
    background-position: 0 0;
  }
  100% {
    color: #333;
    background-position: 100% 0;
  }
}

@keyframes reverseSlideBackground {
  0% {
    color: #fff;
    background-position: 0 0;
  }
  100% {
    color: #333;
    background-position: 100% 0;
  }
}

.email-form-container {
  background-color: #f1ae3e;
  border-radius: 8px;
  width: 100%;
}

form {
  padding: 20px;
  font-family: 'Google Sans', sans-serif;
  color: #333;
}

form div {
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

form div label {
  margin-bottom: 5px;
  font-size: 20px;
}

form div input[type="text"],
form div input[type="email"],
form div textarea {
  padding: 8px;
  border: 2px solid #fff;
  border-radius: 5px;
  background-color: #fff;
  -webkit-transition: border-color 0.3s ease;
  transition: border-color 0.3s ease;
  font-size: 20px;
  font-family: 'Google Sans', sans-serif;
}

form div input[type="text"]:focus,
form div input[type="email"]:focus,
form div textarea:focus {
  border-color: #007bff;
}

form button[type="submit"] {
  width: 100%;
}
