/************************************************* KeyFrames **************************************************/
@-webkit-keyframes invertColors {
  0% {
    background-color: #fff;
    border-color: #fff;
    color: #333;
  }
  100% {
    background-color: #333;
    border-color: #333;
    color: #fff;
  }
}
@keyframes invertColors {
  0% {
    background-color: #fff;
    border-color: #fff;
    color: #333;
  }
  100% {
    background-color: #333;
    border-color: #333;
    color: #fff;
  }
}

@-webkit-keyframes reverseInvertColors {
  0% {
    background-color: #333;
    border-color: #333;
    color: #fff;
  }
  100% {
    background-color: #fff;
    border-color: #fff;
    color: #333;
  }
}

@keyframes reverseInvertColors {
  0% {
    background-color: #333;
    border-color: #333;
    color: #fff;
  }
  100% {
    background-color: #fff;
    border-color: #fff;
    color: #333;
  }
}

@-webkit-keyframes rainbowBorder {
  0% {
    border-image: #fff, 7;
  }
  14% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(white), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, white, white, white, white, white, white) 7;
  }
  28% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, white, white, white, white, white) 7;
  }
  42% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, white, white, white, white) 7;
  }
  56% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, white, white, white) 7;
  }
  70% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, white, white) 7;
  }
  85% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, white) 7;
  }
  100% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(violet)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet) 7;
  }
}

@keyframes rainbowBorder {
  0% {
    -o-border-image: #fff, 7;
       border-image: #fff, 7;
  }
  14% {
    -o-border-image: linear-gradient(to right, red, white, white, white, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(white), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, white, white, white, white, white, white) 7;
  }
  28% {
    -o-border-image: linear-gradient(to right, red, orange, white, white, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, white, white, white, white, white) 7;
  }
  42% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, white, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, white, white, white, white) 7;
  }
  56% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, white, white, white) 7;
  }
  70% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, blue, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, blue, white, white) 7;
  }
  85% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, white) 7;
  }
  100% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(violet)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet) 7;
  }
}

@-webkit-keyframes reverseRainbowBorder {
  0% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(violet)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet) 7;
  }
  14% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, white) 7;
  }
  28% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, white, white) 7;
  }
  42% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, white, white, white) 7;
  }
  56% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, white, white, white, white) 7;
  }
  70% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, white, white, white, white, white) 7;
  }
  85% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(white), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, white, white, white, white, white, white) 7;
  }
  100% {
    border-image: #fff, 7;
  }
}

@keyframes reverseRainbowBorder {
  0% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(violet)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet) 7;
  }
  14% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, white) 7;
  }
  28% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, blue, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, blue, white, white) 7;
  }
  42% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, white, white, white) 7;
  }
  56% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, white, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, white, white, white, white) 7;
  }
  70% {
    -o-border-image: linear-gradient(to right, red, orange, white, white, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, white, white, white, white, white) 7;
  }
  85% {
    -o-border-image: linear-gradient(to right, red, white, white, white, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(white), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, white, white, white, white, white, white) 7;
  }
  100% {
    -o-border-image: #fff, 7;
       border-image: #fff, 7;
  }
}

@-webkit-keyframes slideBackground {
  0% {
    background-position: 100% 0;
    color: #333;
  }
  100% {
    background-position: 0 0;
    color: #fff;
  }
}

@keyframes slideBackground {
  0% {
    background-position: 100% 0;
    color: #333;
  }
  100% {
    background-position: 0 0;
    color: #fff;
  }
}

@-webkit-keyframes reverseSlideBackground {
  0% {
    color: #fff;
    background-position: 0 0;
  }
  100% {
    color: #333;
    background-position: 100% 0;
  }
}

@keyframes reverseSlideBackground {
  0% {
    color: #fff;
    background-position: 0 0;
  }
  100% {
    color: #333;
    background-position: 100% 0;
  }
}

.navbar-container {
  position: fixed;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  z-index: 6;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .navbar-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.navbar-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.navbar-growing-logo {
  z-index: 2;
}

.navbar-logo {
  position: absolute;
  top: 0;
  height: 100px;
}

.navbar-contents {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media screen and (max-width: 768px) {
  .navbar-contents {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.navbar-scrim {
  z-index: 1;
}

.menu-toggle {
  z-index: 2;
  color: #f1ae3e;
  display: none;
  position: absolute;
  right: 20px;
  font-size: 1.7em;
}

@media screen and (max-width: 768px) {
  .menu-toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.navbar-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .navbar-buttons {
    display: none;
    /* Hide buttons by default on small screens */
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    position: absolute;
    left: -96px;
  }
  .navbar-buttons.menuIsOpen {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* Show buttons when the menu is open */
    top: 128px;
  }
  .navbar-buttons.menuIsOpen :nth-child(1) {
    -webkit-animation: fadeInUpOne 0.1s forwards ease-in;
            animation: fadeInUpOne 0.1s forwards ease-in;
  }
  .navbar-buttons.menuIsOpen :nth-child(2) {
    -webkit-animation: fadeInUpTwo 0.3s forwards ease-in;
            animation: fadeInUpTwo 0.3s forwards ease-in;
  }
  .navbar-buttons.menuIsOpen :nth-child(3) {
    -webkit-animation: fadeInUpThree 0.5s forwards ease-in;
            animation: fadeInUpThree 0.5s forwards ease-in;
  }
  .navbar-buttons.menuIsOpen :nth-child(4) {
    -webkit-animation: fadeInUpFour 0.7s forwards ease-in;
            animation: fadeInUpFour 0.7s forwards ease-in;
  }
  .navbar-buttons.menuIsOpen :nth-child(5) {
    -webkit-animation: fadeInUpFive 0.9s forwards ease-in;
            animation: fadeInUpFive 0.9s forwards ease-in;
  }
}

@-webkit-keyframes fadeInUpOne {
  from {
    opacity: 0;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes fadeInUpOne {
  from {
    opacity: 0;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes fadeInUpTwo {
  from {
    opacity: 0;
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes fadeInUpTwo {
  from {
    opacity: 0;
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes fadeInUpThree {
  from {
    opacity: 0;
    -webkit-transform: translateY(-60px);
            transform: translateY(-60px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes fadeInUpThree {
  from {
    opacity: 0;
    -webkit-transform: translateY(-60px);
            transform: translateY(-60px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes fadeInUpFour {
  from {
    opacity: 0;
    -webkit-transform: translateY(-80px);
            transform: translateY(-80px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes fadeInUpFour {
  from {
    opacity: 0;
    -webkit-transform: translateY(-80px);
            transform: translateY(-80px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes fadeInUpFive {
  from {
    opacity: 0;
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes fadeInUpFive {
  from {
    opacity: 0;
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
