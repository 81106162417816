@import '../../utils/utils.scss';

.calendar-day {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;

  border-radius: 4px;
  transition: background-color 0.2s ease;
  width: 300px;
  
  &.selected {
    background-color: #eee;
  }
  
  .day-header {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .time-slots {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: center;

  }
  
  .time-slot {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    padding: 10px;
    background-color: #13258f; /* Blue background */
    color: #fff; /* White text */
    border-radius: 4px; /* Rounded corners */
    width: 135px;
    justify-content: center;

    &:hover {
      background-color: white;
      color: #13258f;
    }
  }  

  .empty-time-slot {
    cursor: default;
    background-color: darkgray;
    color: black;
    font-weight: bold;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 115px;

    &:hover {
      background-color: darkgray;
      color: black;
    }
  }
}