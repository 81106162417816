@import '../../utils/utils.scss';

.navbar-container {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 6;
  justify-content: center;
  text-align: center;

  @media screen and (max-width: $phone-max-width) {
    flex-direction: column;
  }
}

.navbar-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.navbar-growing-logo {
  z-index: 2;
}

.navbar-logo {
  position: absolute;
  top: 0;
  height: 100px;
}

.navbar-contents {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: $phone-max-width) {
    justify-content: center;
  }
}

.navbar-scrim {
  z-index: 1;
}

.menu-toggle {
  z-index: 2;
  color: $theme-gold;
  display: none;
  position: absolute;
  right: 20px;
  font-size: 1.7em;

  @media screen and (max-width: $phone-max-width) {
    display: flex;
  }
}

.navbar-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  
  @media screen and (max-width: $phone-max-width) {
    display: none; /* Hide buttons by default on small screens */
    flex-direction: column;
    position: absolute;
    left: -96px;
    // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Dropdown box shadow */

    &.menuIsOpen {
      display: flex; /* Show buttons when the menu is open */
      top: 128px;

      :nth-child(1) {
        animation: fadeInUpOne 0.1s forwards ease-in;
      }

      :nth-child(2) {
        animation: fadeInUpTwo 0.3s forwards ease-in;
      }

      :nth-child(3) {
        animation: fadeInUpThree 0.5s forwards ease-in;
      }

      :nth-child(4) {
        animation: fadeInUpFour 0.7s forwards ease-in;
      }

      :nth-child(5) {
        animation: fadeInUpFive 0.9s forwards ease-in;
      }
    }
  }
}

@keyframes fadeInUpOne {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUpTwo {
  from {
    opacity: 0;
    transform: translateY(-40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUpThree {
  from {
    opacity: 0;
    transform: translateY(-60px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUpFour {
  from {
    opacity: 0;
    transform: translateY(-80px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUpFive {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

