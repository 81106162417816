/************************************************* KeyFrames **************************************************/
@-webkit-keyframes invertColors {
  0% {
    background-color: #fff;
    border-color: #fff;
    color: #333;
  }
  100% {
    background-color: #333;
    border-color: #333;
    color: #fff;
  }
}
@keyframes invertColors {
  0% {
    background-color: #fff;
    border-color: #fff;
    color: #333;
  }
  100% {
    background-color: #333;
    border-color: #333;
    color: #fff;
  }
}

@-webkit-keyframes reverseInvertColors {
  0% {
    background-color: #333;
    border-color: #333;
    color: #fff;
  }
  100% {
    background-color: #fff;
    border-color: #fff;
    color: #333;
  }
}

@keyframes reverseInvertColors {
  0% {
    background-color: #333;
    border-color: #333;
    color: #fff;
  }
  100% {
    background-color: #fff;
    border-color: #fff;
    color: #333;
  }
}

@-webkit-keyframes rainbowBorder {
  0% {
    border-image: #fff, 7;
  }
  14% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(white), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, white, white, white, white, white, white) 7;
  }
  28% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, white, white, white, white, white) 7;
  }
  42% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, white, white, white, white) 7;
  }
  56% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, white, white, white) 7;
  }
  70% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, white, white) 7;
  }
  85% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, white) 7;
  }
  100% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(violet)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet) 7;
  }
}

@keyframes rainbowBorder {
  0% {
    -o-border-image: #fff, 7;
       border-image: #fff, 7;
  }
  14% {
    -o-border-image: linear-gradient(to right, red, white, white, white, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(white), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, white, white, white, white, white, white) 7;
  }
  28% {
    -o-border-image: linear-gradient(to right, red, orange, white, white, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, white, white, white, white, white) 7;
  }
  42% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, white, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, white, white, white, white) 7;
  }
  56% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, white, white, white) 7;
  }
  70% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, blue, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, blue, white, white) 7;
  }
  85% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, white) 7;
  }
  100% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(violet)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet) 7;
  }
}

@-webkit-keyframes reverseRainbowBorder {
  0% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(violet)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet) 7;
  }
  14% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, white) 7;
  }
  28% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, white, white) 7;
  }
  42% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, white, white, white) 7;
  }
  56% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, white, white, white, white) 7;
  }
  70% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, white, white, white, white, white) 7;
  }
  85% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(white), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, white, white, white, white, white, white) 7;
  }
  100% {
    border-image: #fff, 7;
  }
}

@keyframes reverseRainbowBorder {
  0% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(violet)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet) 7;
  }
  14% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, white) 7;
  }
  28% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, blue, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, blue, white, white) 7;
  }
  42% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, white, white, white) 7;
  }
  56% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, white, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, white, white, white, white) 7;
  }
  70% {
    -o-border-image: linear-gradient(to right, red, orange, white, white, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, white, white, white, white, white) 7;
  }
  85% {
    -o-border-image: linear-gradient(to right, red, white, white, white, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(white), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, white, white, white, white, white, white) 7;
  }
  100% {
    -o-border-image: #fff, 7;
       border-image: #fff, 7;
  }
}

@-webkit-keyframes slideBackground {
  0% {
    background-position: 100% 0;
    color: #333;
  }
  100% {
    background-position: 0 0;
    color: #fff;
  }
}

@keyframes slideBackground {
  0% {
    background-position: 100% 0;
    color: #333;
  }
  100% {
    background-position: 0 0;
    color: #fff;
  }
}

@-webkit-keyframes reverseSlideBackground {
  0% {
    color: #fff;
    background-position: 0 0;
  }
  100% {
    color: #333;
    background-position: 100% 0;
  }
}

@keyframes reverseSlideBackground {
  0% {
    color: #fff;
    background-position: 0 0;
  }
  100% {
    color: #333;
    background-position: 100% 0;
  }
}

.scrim-container {
  position: absolute;
  z-index: 1;
}

.scrim {
  position: absolute;
  opacity: 0.2;
}

.scrim-on-hover {
  opacity: 0;
  -webkit-transition: 1s;
  transition: 1s;
}

.scrim-on-hover.hovering {
  opacity: 0.2;
}

.blur {
  position: absolute;
  -webkit-font-smoothing: antialiased;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
}
