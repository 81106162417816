$text-color: #333;
$background-color: #fff;

$theme-steel-blue: #71797E;
$theme-gold: #f1ae3e;

$short-transition-time: 0.3s;
$long-transition-time: 1s;

$phone-max-width: 800px;

/************************************************* KeyFrames **************************************************/

@keyframes invertColors {
  0% {
    background-color: $background-color;
    border-color: $background-color;
    color: $text-color;
  }
  100% {
    background-color: $text-color;
    border-color: $text-color;
    color: $background-color;
  }
}

@keyframes reverseInvertColors {
  0% {
    background-color: $text-color;
    border-color: $text-color;
    color: $background-color;
  }
  100% {
    background-color: $background-color;
    border-color: $background-color;
    color: $text-color;
  }
}

@keyframes rainbowBorder {
  0% {
    border-image: $background-color, 7;
  }
  14% {
    border-image: linear-gradient(to right, red, white, white, white, white, white, white) 7;
  }
  28% {
    border-image: linear-gradient(to right, red, orange, white, white, white, white, white) 7;
  }
  42% {
    border-image: linear-gradient(to right, red, orange, yellow, white, white, white, white) 7;
  }
  56% {
    border-image: linear-gradient(to right, red, orange, yellow, green, white, white, white) 7;
  }
  70% {
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, white, white) 7;
  }
  85% {
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, white) 7;
  }
  100% {
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet) 7;
  }
}

@keyframes reverseRainbowBorder {
  0% {
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet) 7;
  }
  14% {
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, white) 7;
  }
  28% {
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, white, white) 7;
  }
  42% {
    border-image: linear-gradient(to right, red, orange, yellow, green, white, white, white) 7;
  }
  56% {
    border-image: linear-gradient(to right, red, orange, yellow, white, white, white, white) 7;
  }
  70% {
    border-image: linear-gradient(to right, red, orange, white, white, white, white, white) 7;
  }
  85% {
    border-image: linear-gradient(to right, red, white, white, white, white, white, white) 7;
  }
  100% {
    border-image: $background-color, 7;
  }
}

@keyframes slideBackground {
  0% {
    background-position: 100% 0; // Initial position at 100%
    color: $text-color;
  }
  100% {
    background-position: 0 0; // Shifts gradient to the right, ending position at 0%
    color: $background-color;
  }
}

@keyframes reverseSlideBackground {
  0% {
    color: $background-color;
    background-position: 0 0; // Initial position at 0%
  }
  100% {
    color: $text-color;
    background-position: 100% 0; // Shifts gradient to the left, ending position at 100%
  }
}