/************************************************* KeyFrames **************************************************/
@-webkit-keyframes invertColors {
  0% {
    background-color: #fff;
    border-color: #fff;
    color: #333;
  }
  100% {
    background-color: #333;
    border-color: #333;
    color: #fff;
  }
}
@keyframes invertColors {
  0% {
    background-color: #fff;
    border-color: #fff;
    color: #333;
  }
  100% {
    background-color: #333;
    border-color: #333;
    color: #fff;
  }
}

@-webkit-keyframes reverseInvertColors {
  0% {
    background-color: #333;
    border-color: #333;
    color: #fff;
  }
  100% {
    background-color: #fff;
    border-color: #fff;
    color: #333;
  }
}

@keyframes reverseInvertColors {
  0% {
    background-color: #333;
    border-color: #333;
    color: #fff;
  }
  100% {
    background-color: #fff;
    border-color: #fff;
    color: #333;
  }
}

@-webkit-keyframes rainbowBorder {
  0% {
    border-image: #fff, 7;
  }
  14% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(white), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, white, white, white, white, white, white) 7;
  }
  28% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, white, white, white, white, white) 7;
  }
  42% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, white, white, white, white) 7;
  }
  56% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, white, white, white) 7;
  }
  70% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, white, white) 7;
  }
  85% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, white) 7;
  }
  100% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(violet)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet) 7;
  }
}

@keyframes rainbowBorder {
  0% {
    -o-border-image: #fff, 7;
       border-image: #fff, 7;
  }
  14% {
    -o-border-image: linear-gradient(to right, red, white, white, white, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(white), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, white, white, white, white, white, white) 7;
  }
  28% {
    -o-border-image: linear-gradient(to right, red, orange, white, white, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, white, white, white, white, white) 7;
  }
  42% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, white, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, white, white, white, white) 7;
  }
  56% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, white, white, white) 7;
  }
  70% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, blue, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, blue, white, white) 7;
  }
  85% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, white) 7;
  }
  100% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(violet)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet) 7;
  }
}

@-webkit-keyframes reverseRainbowBorder {
  0% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(violet)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet) 7;
  }
  14% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, white) 7;
  }
  28% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, white, white) 7;
  }
  42% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, green, white, white, white) 7;
  }
  56% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, yellow, white, white, white, white) 7;
  }
  70% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, orange, white, white, white, white, white) 7;
  }
  85% {
    border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(white), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
    border-image: linear-gradient(to right, red, white, white, white, white, white, white) 7;
  }
  100% {
    border-image: #fff, 7;
  }
}

@keyframes reverseRainbowBorder {
  0% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(violet)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet) 7;
  }
  14% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, white) 7;
  }
  28% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, blue, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, blue, white, white) 7;
  }
  42% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, green, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, green, white, white, white) 7;
  }
  56% {
    -o-border-image: linear-gradient(to right, red, orange, yellow, white, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, yellow, white, white, white, white) 7;
  }
  70% {
    -o-border-image: linear-gradient(to right, red, orange, white, white, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, orange, white, white, white, white, white) 7;
  }
  85% {
    -o-border-image: linear-gradient(to right, red, white, white, white, white, white, white) 7;
       border-image: -webkit-gradient(linear, left top, right top, from(red), color-stop(white), color-stop(white), color-stop(white), color-stop(white), color-stop(white), to(white)) 7;
       border-image: linear-gradient(to right, red, white, white, white, white, white, white) 7;
  }
  100% {
    -o-border-image: #fff, 7;
       border-image: #fff, 7;
  }
}

@-webkit-keyframes slideBackground {
  0% {
    background-position: 100% 0;
    color: #333;
  }
  100% {
    background-position: 0 0;
    color: #fff;
  }
}

@keyframes slideBackground {
  0% {
    background-position: 100% 0;
    color: #333;
  }
  100% {
    background-position: 0 0;
    color: #fff;
  }
}

@-webkit-keyframes reverseSlideBackground {
  0% {
    color: #fff;
    background-position: 0 0;
  }
  100% {
    color: #333;
    background-position: 100% 0;
  }
}

@keyframes reverseSlideBackground {
  0% {
    color: #fff;
    background-position: 0 0;
  }
  100% {
    color: #333;
    background-position: 100% 0;
  }
}

.button {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  padding: 15px;
  color: #333;
  cursor: pointer;
  z-index: 1;
}

.fill-button {
  background-color: #fff;
  border: #fff 3px solid;
}

.fill-button:hover {
  -webkit-animation: invertColors 0.3s forwards;
          animation: invertColors 0.3s forwards;
}

.fill-button:not(:hover) {
  -webkit-animation: reverseInvertColors 0.3s forwards;
          animation: reverseInvertColors 0.3s forwards;
}

.sliding {
  min-width: 150px;
  background: -webkit-gradient(linear, right top, left top, color-stop(50.05%, #fff), color-stop(50%, #333));
  background: linear-gradient(to left, #fff 50.05%, #333 50%);
  background-color: #fff;
  background-size: 200% 100%;
}

.sliding:hover {
  -webkit-animation: rainbowBorder 0.3s forwards, slideBackground 0.3s forwards;
          animation: rainbowBorder 0.3s forwards, slideBackground 0.3s forwards;
}

.sliding:not(:hover) {
  -webkit-animation: reverseRainbowBorder 0.3s forwards, reverseSlideBackground 0.3s forwards;
          animation: reverseRainbowBorder 0.3s forwards, reverseSlideBackground 0.3s forwards;
}

.navbar-button {
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 15px 25px;
  font-size: 0.5em;
  color: #f1ae3e;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .navbar-button {
    position: relative;
    background-color: #333;
    border: 1px solid #f1ae3e;
  }
}

.navbar-text {
  margin-bottom: 5px;
}

@media screen and (max-width: 768px) {
  .navbar-text {
    margin-bottom: 0px;
  }
}

.line {
  bottom: 0;
  left: 0;
  width: 0;
  /* Initially set width to 0 to hide the line */
  height: 3px;
  /* Adjust line height */
  background-color: #f1ae3e;
  /* Adjust line color */
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
  /* Apply transition to width property */
}

@media screen and (max-width: 768px) {
  .line {
    display: none;
  }
}

.navbar-button:hover .line {
  width: 100%;
  /* Expand the line width on hover */
}

/* If the page corresponding to this button is currently open, keep the line visible. */
.navbar-button.active .line {
  width: 100%;
}

.icon-button .background {
  color: transparent;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.icon-button .icon {
  color: #fff;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.icon-button:hover .background {
  color: #fff;
}

.icon-button:hover .icon {
  color: #333;
}

.logo-button {
  -ms-flex-item-align: normal;
      -ms-grid-row-align: normal;
      align-self: normal;
  width: 50px;
}
